.cover {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: grid;
    gap: 40px;
    width: 90%;
}

#cover-container h1 {
    font-size: 7em;
    width: fit-content;
    padding: 10px 30px;
    margin: auto;
}

#cover-container h2 {
    font-family: 'Solitreo', cursive;
    font-size: 2em;
}

.box-container {
    padding-top: 10px;
}

h6 {
    text-align: center;
}

.next-button {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 5;
}

.btn-link.spotify-background-color {
    color: white;
}

.next-button.music button {
    color: white;
}

.next-button.music button:hover,
.next-button.music button:focus,
#definitions-button:hover,
#definitions-button:focus {
    background-color: #1aa945;
}

.next-button.music button:active,
#definitions-button:active {
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.next-button button {
    font-size: 25px;
}

#moods {
    margin-top: 10px;
}

.music-section,
.television-section,
.podcast-section {
    position: absolute;
    width: 100%;
}

#moods,
#result-section {
    padding-bottom: 100px;
}

#result-section.podcast {
    padding-top: 30px;
}

#result-section.music {
    padding-top: 13px;
}

.music-genre-dnd,
.television-genre-dnd,
.podcast-genre-dnd {
    table-layout: fixed;
    width: 70%;
}

.music-genre-dnd th:first-child,
.music-genre-dnd th:last-child,
.television-genre-dnd th:first-child,
.television-genre-dnd th:last-child,
.podcast-genre-dnd th:first-child,
.podcast-genre-dnd th:last-child {
    width: 20%;
}

.fade-in {
    animation: fadeIn 2s linear;
}

.fade-out {
    animation: fadeOut 2s forwards;
}

.fade-in-delayed-half {
    animation: fadeInDelay1 2s linear;
}

.fade-in-delayed-1 {
    animation: fadeInDelay1 4s linear;
}

.fade-in-delayed-2 {
    animation: fadeInDelay2 8s linear;
}

.fade-in-and-out {
    animation: fadeInAndOut 4s forwards;
}

.fade-in-and-out-delayed {
    animation: fadeInAndOutDelayed 8s forwards;
}

.fade-out-and-in {
    animation: fadeOutAndIn 8s forwards;
}

.fade-out-and-in-fast {
    animation: fadeOutAndIn 4s forwards;
}

.circle-container {
    border: 5px solid #DEB887;
    border-radius: 50%;
}

.modal-dialog {
    margin-top: 0px;
    margin-bottom: 0px;
}

.modal-content {
    max-height: 80vh;
}

.modal-title:not(.artist-modal-title) {
    text-align: center;
}

.modal-body {
    overflow: scroll;
}

.modal-body hr {
    border-top: 1px solid rgb(31 30 30)
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fadeInDelay1 {

    0%,
    50% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fadeInDelay2 {

    0%,
    80% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fadeOut {

    0% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes fadeInAndOut {

    0%,
    10% {
        opacity: 0;
        visibility: hidden;
    }

    50% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes fadeOutAndIn {

    0% {
        opacity: 1;
        visibility: visible;
    }

    25%,
    75% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fadeInAndOutDelayed {

    0%,
    30% {
        opacity: 0;
        visibility: hidden;
    }

    65% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.mood-buttons-container {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 20px;
    column-gap: 2vw;
}

.mood-buttons-container .slider-container {
    display: flex;
    flex-basis: 100%;
    justify-content: space-around;
}

.circle-four {
    display: flex;
    flex-wrap: wrap;
}

.circle-four button {
    flex-basis: 25%;
}

button.mood {
    font-size: 1em;
    min-height: 125px;
    min-width: 125px;
    border-radius: 50%;
    margin: 0px;
}

.slider-container {
    flex-basis: 21% !important;
    align-items: center;
    justify-content: center;
}

.textContainer {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 100px;
    line-height: 60px;
    text-align: center;
    color: #2ebd59;
}

.textContainer .minute {
    font-size: 25px;
    line-height: 50px;
    background: -webkit-linear-gradient(#00631e, #4af97e);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}