body {
    background-color: #111827;
    color: #0792a0;
}

.home-footer {
    background-color: #0792a0;
    bottom: 0;
    left: 0;
    margin-top: 1rem;
    padding: 2vh 2vw;
    position: fixed;
    text-align: end;
    width: 100%;
}

.home-footer h4 {
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 0;
}

.home-footer a {
    font-size: 20px;
    color: #ffc701;
}

.welcome {
    line-height: 0.8;
    margin-bottom: 2.1rem;
    position: relative;
    top: -5px;
}

.welcome .display-5 {
    margin-bottom: 0px;
}

h1.app-name {
    font-family: 'Gotham';
    font-size: 3.1rem;
    color: #84c7ce;
    font-style: italic;
    position: relative;
    top: -8px;
}

#allow-us {
    font-size: 1.4rem;
    font-family: 'Raleway', sans-serif;
}

.carousel {
    margin-bottom: 20px !important;
}

.carousel.carousel-slider .control-arrow:hover {
    background: none;
}

.nav-link.active,
.nav-link.active:hover,
.nav-link.active:focus {
    color: #0792a0 !important;
}

hr {
    border-top: 1px solid rgba(250, 250, 250, 250);
}

a {
    color: #0366d6;
}

.font-sm {
    font-size: 12px;
}

.dark-carousel .col {
    padding-left: 35px;
    padding-right: 35px;
}

.dark-carousel .control-dots {
    margin: 0px;
}

.dark-carousel .control-dots .dot {
    background-color: black;
}

.dark-carousel .control-arrow {
    background-color: grey !important;
    margin: 0px 4px;
}

.dark-carousel .thumbs-wrapper {
    margin: 0px;
}

.dark-carousel .slide iframe {
    width: 100% !important;
    margin: 0 0px 25px !important;
    padding: 0px 5px !important;
}

.modal.show {
    padding-left: 0px !important;
}

.modal-header,
.modal-title {
    width: 100% !important;
}

.modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.dropdown-toggle {
    display: block;
    width: 100%;
    white-space: inherit;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.fa-info-circle {
    position: relative;
    bottom: 5px;
    cursor: pointer;
}

.badge {
    border-radius: 0.75rem;
}

.spotify-charts-div,
.spotify-featured-playlists-div {
    top: -7px;
}

.spotify-charts-div {
    right: 0px;
}

.spotify-featured-playlists-div {
    left: 0px;
}

.spotify-follow,
.spotify-charts,
.spotify-featured-playlists {
    outline: none !important;
}

.spotify-charts,
.spotify-featured-playlists {
    font-size: .65rem !important;
    min-width: 60px !important;
}

@media screen and (max-width: 500px) and (min-width: 300px) {

    .spotify-charts,
    .spotify-featured-playlists {
        font-size: .9rem !important;
    }

    .spotify-charts-div {
        right: 1rem;
    }

    .spotify-featured-playlists-div {
        left: 1rem;
    }
}

@media screen and (max-width: 700px) and (min-width: 500px) {

    .spotify-charts,
    .spotify-featured-playlists {
        font-size: .9rem !important;
    }

    .spotify-charts-div {
        right: 2rem;
    }

    .spotify-featured-playlists-div {
        left: 2rem;
    }
}

@media screen and (min-width: 700px) {

    .spotify-charts,
    .spotify-featured-playlists {
        font-size: 1rem !important;
    }

    .spotify-charts-div {
        right: 10rem;
    }

    .spotify-featured-playlists-div {
        left: 10rem;
    }
}

.spotify-charts .bt-text,
.spotify-featured-playlists .bt-text,
.spotify-link .bt-text {
    display: block;
    font-weight: 200;
    letter-spacing: 1px;
    margin-left: 13px;
    padding: 4px 0 3px 5px !important;
    text-transform: uppercase;
}

button.close {
    padding: 1rem 2rem !important;
    position: absolute;
    right: 0;
    outline: none;
}

.bd {
    padding-top: 3px;
}

.bd a {
    color: #333;
}

.bd h1 {
    display: none;
    font-size: 14px;
    margin: 7px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bd button,
.bd a.spotify-link {
    background: #2ebd59;
    border: none;
    border-radius: 500px;
    color: #fff;
    cursor: pointer;
    float: left;
    font-size: 11px;
    line-height: 11px;
    margin: 0 10px 0 0;
    min-width: 80px;
    padding-bottom: 3px;
    padding-top: 2px;
    position: relative;
    text-align: center;
    white-space: nowrap;
}

.bd .spotify-featured-playlists {
    margin: 0 0 0 10px;
}

.bd a.spotify-link {
    font-size: 15px;
}

.bt-icon {
    background-image: url(https://open.scdn.co/cdn/images/follow/sprite@1.0435c9db.png);
    background-position: 0 0;
    content: "";
    display: inline-block;
    height: 17px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 17px;
}

.bt-text {
    display: block;
    font-weight: 200;
    letter-spacing: 1px;
    margin-left: 13px;
    padding: 4px 4px 3px 8px;
    text-transform: uppercase;
}

.spotify-link .bt-text {
    text-transform: none;
}

.count {
    display: inline-block;
    -webkit-perspective: 600px;
    perspective: 600px;
}

.img,
.widget {
    overflow: hidden;
}

.widget {
    font-weight: 700;
    zoom: 1;
}

.widget a .img:before {
    -webkit-box-shadow: inset 0 0 2px rgb(214 213 210 / 80%);
    box-shadow: inset 0 0 2px rgb(214 213 210 / 80%);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.detail .img,
.detail h1 {
    display: block;
}

.detail .img {
    display: block;
}

.img {
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 56px;
    display: none;
    float: left;
    height: 56px;
    margin-right: 10px;
    position: relative;
    width: 56px;
}

.count-num {
    font-size: 11px;
    background: #fff;
    border: 1px solid #d6d5d2;
    border-radius: 3px;
    color: #999896;
    line-height: 10px;
    margin-left: -1px;
    min-width: 34px;
    padding: 6px 8px;
    text-align: center;
    white-space: nowrap;
}

.count-num:before {
    border-right-color: #d6d5d2 !important;
    border-width: 6px !important;
    top: 6px;
}

.count-num:after {
    border-right-color: #fff !important;
    border-width: 4.5px !important;
    top: 7.5px;
}

.count-num:before,
.count-num:after {
    border: solid transparent;
    content: " ";
    height: 0;
    position: absolute;
    right: 100%;
    width: 0;
}

.count-num.back,
.count-num.front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform .4s ease-in-out;
    transition: -webkit-transform .4s ease-in-out;
    transition: transform .4s ease-in-out;
    transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
}

.count-num.front {
    bottom: 0.4rem;
    position: absolute;
    -webkit-transform: rotateX(0deg) rotateY(0deg);
    transform: rotateX(0deg) rotateY(0deg);
    z-index: 900;
}

.count-num.back {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
    z-index: 800;
}

.no-border {
    border: none !important;
}

.row-striped.bordered {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 2px solid grey;
    border-radius: 0.2rem;
}

.row-striped:nth-of-type(even) .row-striped:nth-of-type(odd) {
    background-color: #c2efc2;
}

.row-striped:nth-of-type(odd) .row-striped:nth-of-type(even) {
    background-color: #c2efc2;
}

.artist-top-tracks-playlist-collapsible {
    width: 90%;
    display: block;
    margin: auto;
}

.dropdown-item:hover {
    background-color: #ecf0f3;
}

.dropdown-item:focus {
    color: #16181B;
}

.dropdown-item.spotify-background-color:hover,
.dropdown-item.spotify-background-color:focus {
    background-color: #11c852;
}

.spinner-collapse {
    height: 50px;
    padding-top: 10px;
}

.link-primary,
.btn-link {
    text-decoration: underline;
}

.btn-block.submit {
    width: 50%;
    margin: auto;
}

ol {
    padding-inline-start: 20px;
}

.fake-table {
    max-width: 95vw;
}

.table {
    margin: auto;
    max-width: 100%;
    margin-bottom: 5px;
}

.max-width-100 {
    max-width: 100% !important;
}

.spotify-collapse {
    margin: auto;
    width: fit-content;
}

.error-message {
    max-width: 400px;
}

.mood-description {
    max-width: fit-content;
}

.MuiIconButton-label .MuiSvgIcon-root {
    transform: scale(2);
}

@media only screen and (max-width: 600px) {
    .table {
        width: max-content !important;
        max-width: 200%;
    }

    .error-message {
        max-width: 300px;
    }
}

.table>tbody>tr>td {
    vertical-align: middle;
}

.artists-cell {
    min-width: 170px;
    max-width: 265px;
}

.related-artists-cell {
    min-width: 130px;
}

.genres-cell {
    margin: auto;
    max-width: 230px;
}

.television-title-cell {
    max-width: 280px;
    margin: auto;
}

table td,
table th {
    text-align: center;
    padding: 6px;
}

.col-centered {
    float: none;
    margin: 0 auto;
}

.hide-overflow {
    overflow: hidden;
}

.refresh-icon {
    display: block;
    cursor: pointer;
}

button {
    border-radius: 6px;
}

button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}

code {
    color: #E01A76;
}

audio {
    width: 250px;
}

.scale-label {
    text-decoration: underline;
    font-family: monospace;
}

.youtube-iframe {
    width: 300px;
    height: 200px;
}

@media only screen and (max-width: 600px) {
    .youtube-iframe {
        width: 200px;
        height: 140px;
    }
}


.component-header {
    text-align: center;
    color: #F5F5DC;
}

.text-color-burlywood {
    color: #DEB887;
}

.pacman-loading {
    color: #FFFF00;
}

.list-inline {
    display: flex;
    justify-content: center;
    list-style: inherit;
}

.center-form {
    margin: 0 auto;
    width: fit-content;
    width: 100%;
    position: relative;
}

.padding-inline-start-0 {
    padding-inline-start: 0;
}

.fade-enter {
    opacity: 0;
    transform: scale(0.9);
}

.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 900ms, transform 900ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.chart-scale {
    width: fit-content;
    margin-bottom: 7px !important;
}

.glow:before {
    top: -5px !important;
    left: -5px !important;
    width: calc(100% + 10px) !important;
    height: calc(100% + 10px) !important;
}

.glow-on-hover:before,
.glow:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after,
.glow:after {
    background: transparent;
}

.glow-on-hover:hover:before,
.glow:before {
    opacity: 1;
}

.glow-on-hover:after,
.glow:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.btn-scale {
    width: 13%;
    margin: 0 4px;
    text-align: center;
    font-weight: bold;
    color: black;
    font-family: 'Lato', sans-serif;
    position: relative;
    border: none;
    outline: none;
    border-radius: 10px;
}

.btn-scale-asc-1,
.btn-scale-desc-5 {
    background-color: #33FF00;
}

.btn-scale-asc-1:hover,
.btn-scale-desc-5:hover {
    background-color: #2CDE00;
}


.btn-scale-asc-2,
.btn-scale-desc-4 {
    background-color: #99FF00;
}

.btn-scale-asc-2:hover,
.btn-scale-desc-4:hover {
    background-color: #85DE00;
}

.btn-scale-asc-3,
.btn-scale-desc-3 {
    background-color: #FFFF00;
}

.btn-scale-asc-3:hover,
.btn-scale-desc-3:hover {
    background-color: #DEDE00;
}

.btn-scale-asc-4,
.btn-scale-desc-2 {
    background-color: #FF9900;
}

.btn-scale-asc-4:hover,
.btn-scale-desc-2:hover {
    background-color: #DE8500;
}

.btn-scale-asc-5,
.btn-scale-desc-1 {
    background-color: #FF0000;
}

.btn-scale-asc-5:hover,
.btn-scale-desc-1:hover {
    background-color: #DE0000;
}

.container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
}

#home {
    max-width: 1100px;
    margin: auto;
}

@media only screen and (max-width: 600px) {
    .draggable-search-input {
        width: 110px;
    }
}

.search-label {
    position: relative;
}

.search-icon {
    position: absolute;
    top: 10px;
    font-size: 12px;
    color: #555;
    right: 10px;
}

.button.draggable {
    pointer-events: none;
    min-width: 0;
    width: 20px;
    height: 20px;
    pointer-events: none;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background-color: cadetblue;
    background-image: url(../src/images/icons/drag-drop.svg);
    background-size: 17px 17px;
    background-repeat: no-repeat;
    display: inline-block;
    background-position: center;
    padding: 0;
}

#listen-notes-logo {
    height: 25px;
    width: 13%;
    right: 10px;
    position: absolute;
}

.switch-div {
    padding-left: 1.5rem;
    font-size: 0.9rem;
}

.custom-control-label::before,
.custom-control-label::after {
    cursor: pointer;
}

.spotify-font {
    font-family: 'Gotham';
}

.spotify-text-color {
    color: #1DB954;
}

.spotify-background-color {
    background-color: #1DB954;
}

.artist-name {
    padding-right: .45rem;
    padding-left: .45rem;
}

.spotify-icon {
    font-size: 18px;
}

.add-to-playlist-toggle {
    position: relative;
    top: 1px;
}

.card-header button {
    font-size: 16px;
}

.table-sm {
    font-size: .8rem;
}

/*always show scroll-bar on responsive tables on mobile*/
.table-responsive::-webkit-scrollbar {
    -webkit-appearance: none;
}

.table-responsive::-webkit-scrollbar:vertical {
    width: 6px;
}

.table-responsive::-webkit-scrollbar:horizontal {
    height: 6px;
}

.table-responsive::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

.table-responsive::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}

.small-info {
    display: block;
    width: 95%;
    margin: auto;
    line-height: normal;
}


/*COLLAPSIBLE*/
.Collapsible {
    background-color: #0792A0;
    color: #c49c68;
}

/*The content within the collaspable area*/
.Collapsible__contentInner {
    background-color: lightgrey;
    padding: 10px;
    border: 1px solid lightgrey;
    border-top: 0;
}

.Collapsible__contentInner div {
    font-size: 14px;
    line-height: 20px;
}

.Collapsible__contentInner div:last-child {
    margin-bottom: 0;
}

/*The link which when clicked opens the collapsable area*/
.Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    color: grey;
    position: relative;
    border: 1px solid white;
    padding: 10px;
    color: white;
}

.Collapsible__trigger:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
}

.Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg);
}


.Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
}

.MoodTriggerCSS {
    background-color: #343A40;
}

.CustomTriggerCSS {
    background-color: #1DB954;
    transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
    background-color: darkslateblue;
}

.Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    background-color: #CBB700;
    color: black;
}