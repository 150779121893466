@media (min-width: 700px) {
    .container {
        max-width: 90vw;
    }

    #music .sections,
    #television .sections,
    #podcasts .sections {
        display: flex;
        gap: 5px;
        align-items: flex-start;
        justify-content: center;
    }

    .Collapsible {
        color: #c49c68;
    }

    .Collapsible.is-open {
        background-color: lightgray;
    }

    .Collapsible.music-section {
        width: 50%;
    }

    .music-section.double {
        display: grid;
        gap: 10px;
    }
}