@media screen and (max-width: 500px) {
    h1.welcome {
        margin-bottom: 2.6rem;
    }
    
    #listen-notes-logo {
        height: 17px;
        width: 55px;
    }

    #cover-container h1.television,
    #cover-container h1.podcast {
        font-size: 4em;
    }

    .mood-buttons-container div {
        row-gap: 25px;
    }

    button.mood {
        font-size: 11px !important;
        min-height: 85px !important;
        min-width: 85px !important;
    }

    .double button.mood {
        min-height: 65px !important;
        min-width: 65px !important;
    }

    .slider-container {
        flex-basis: 40% !important;
    }

    .textContainer {
        row-gap: 0px !important;
        height: 40px !important;
        font-size: 45px !important;
        line-height: 15px !important;
    }

    .textContainer .minute {
        align-items: center !important;
        font-size: 16px !important;
        line-height: 45px !important;
    }

    .music-genre-dnd,
    .television-genre-dnd,
    .podcast-genre-dnd {
        width: 100% !important;
    }

    .music-genre-dnd th:first-child,
    .music-genre-dnd th:last-child,
    .television-genre-dnd th:first-child,
    .television-genre-dnd th:last-child,
    .podcast-genre-dnd th:first-child,
    .podcast-genre-dnd th:last-child {
        width: 30% !important;
    }
}