button.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.nav-item .nav-link,
button.navbar-brand {
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
}

.nav-item .nav-link:hover {
  color: #cbd3da !important;
}

.nav-item .nav-link:focus,
button.navbar-brand {
  outline: none;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}